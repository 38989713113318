<template>
  <el-select
    v-model="resellerProductModel"
    @change="resellerProductChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.RESELLER_PRODUCT')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getResellerProducts"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.RESELLER_PRODUCT')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="resellerProduct in resellerProducts"
      :key="resellerProduct.id"
      :value="resellerProduct.id"
      :label="`${resellerProduct.code} - ${resellerProduct.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "purchases-order-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    resellerProduct: {
      type: String,
      default: null,
      description: "ResellerProduct id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
  },

  data() {
    return {
      resellerProductModel: this.resellerProduct,
      resellerProducts: {},
    };
  },

  setup() {},

  created() {
    this.getResellerProducts(null, this.resellerProduct);
  },

  methods: {
    async getResellerProducts(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-updated_at",
          filter: {
            ...(query ? { code: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }

        await this.$store.dispatch("resellerProducts/list", params);
        const resellerProductsArr = await this.$store.getters[
          "resellerProducts/list"
        ];
        this.resellerProducts = {};
        resellerProductsArr.forEach((resellerProduct) => {
          this.resellerProducts[resellerProduct.id] = resellerProduct;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    resellerProductChanged(resellerProductId) {
      const resellerProduct = Object.values(this.resellerProducts).find(
        (item) => item.id === resellerProductId
      );
      this.$emit("resellerProductChanged", resellerProductId, resellerProduct);
    },
  },

  watch: {
    resellerProduct(resellerProduct) {
      if (resellerProduct) {
        this.resellerProductModel = resellerProduct;
        if (resellerProduct !== this.resellerProductModel) {
          this.getResellerProducts(null, resellerProduct);
        }
      } else {
        this.resellerProductModel = null;
      }
    },
    filterReseller() {
      this.getResellerProducts();
    },
  },
};
</script>
