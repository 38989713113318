<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!resellerInvoice">
      <span class="loader"></span>
    </span>
    <reseller-invoice-form
      v-if="resellerInvoice"
      :loading="loading"
      :resellerInvoiceData="resellerInvoice"
      :formErrors="formErrors"
      @resellerInvoiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import ResellerInvoiceForm from "../partials/ResellerInvoiceForm.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { ResellerInvoiceForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    resellerInvoiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      resellerInvoice: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "resellerInvoices/get",
          this.resellerInvoiceId
        );
        this.resellerInvoice =
          this.$store.getters["resellerInvoices/resellerInvoice"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(resellerInvoice) {
      this.loading = true;
      const resellerInvoiceData = cloneDeep(resellerInvoice);
      try {
        await this.$store.dispatch(
          "resellerInvoices/update",
          resellerInvoiceData
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("RESELLER_INVOICES.RESELLER_INVOICE_UPDATED"),
        });
        const resellerInvoice = await this.$store.getters[
          "resellerInvoices/resellerInvoice"
        ];
        this.$emit("onViewResellerInvoice", resellerInvoice, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
